@import "../../settings/setting.scss";

.footer-center-active {
  justify-content: center;
  &__All-of-Us-and-the-Al {
    font-family: $font-family-GothamBook !important;
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    color: $primary-color;
  }
  &__All-of-Us-and-the-Al-not-center {
    font-family: $font-family-GothamBook !important;
    font-size: 12px;
    line-height: 1.33;
    color: $primary-color;
  }
 
  &__All-of-Us-and-the-Al-text-style-4 {
    font-family: $font-family-GothamBook !important;
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    color: $footer-color;
    text-decoration: underline;
  }

  &__text-style-4 {
    text-decoration: none !important;
  }

  &__typography-all-assets-are-uniq {
    width: 670px;
    margin: -8px 192px 14px -38px !important;
    padding: 3px 268px 4px 1 !important;
    font-family: $font-family-GothamBook !important;
    font-size: 14px;
    line-height: 1.14;
    color: $get-started;
  }

  &__footer-mail-link{
    color: $footer-color;
  }
}
