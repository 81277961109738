@media (min-width: 320px) and (max-width: 625px) {
  .assetsHeading {
    font-size: 30px;
  }

  .gMedium {
    font-size: 10px;
  }

  .MuiButtonBase-root.MuiTab-root {
    padding: 0px 0px;
    margin-right: 13px;
    margin-left: 0px;
    font-family: GothamMedium;
    font-size: 9px;
    color: #4a4a4a;
    min-height: 47px;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    max-height: 50px;
    font-family: GothamBlack;
  }

  #commsButton .MuiButton-root {
    padding-left: 0px;
    padding-right: 0px;
  }

  .css-jpln7h-MuiTabs-scroller {
    margin-top: 5px;
    height: 34px;
  }
}
.css-15wrutl-MuiGrid-root {
  padding-top: 0px;
}
.css-1s54xh9-MuiGrid-root {
  padding-top: 0px;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: white;
}
.MuiButtonBase-root.MuiButton-root.underlined {
  font-size: 14px;
  font-family: GothamBook;
  color: #216fb4;
  text-transform: capitalize;
}

.MuiButtonBase-root.MuiButton-root.viewButton {
  padding: 0px 0px 0px 0px;
  border-radius: 0px;
}

.MuiButtonBase-root.MuiButton-root.clearButton {
  padding: 0px 0px 0px 0px;
  border-radius: 0px;
  margin-left: 16px;
}

.MuiButtonBase-root.MuiCheckbox-root {
  padding: 8px;
}
.css-18ldkah-MuiGrid-root {
  padding-top: 0px;
}
.css-hbucu9-MuiGrid-root {
  padding-top: 0px;
}
.MuiToolbar-root {
  padding: 0px 0px;
}
.headerTabs1.Mui-selected {
  margin-right: 41px;
}
#mouse-over-popover1 .MuiPopover-paper {
  width: 400px;
  height: 94px;
  padding: 20px 40px;
  box-shadow: 0px 0px;
  background-color: transparent;
  border-radius: 0px;
}
.MuiListItemText-primary {
  font-family: GothamLight;
  padding-left: 10px;
  font-size: 13px;
  color: #4a4a4a;
  line-height: 14px;
  letter-spacing: -0.18px;
}
.headerTabs.Mui-selected {
  font-size: 12px;
  font-family: GothamBlack;
}
.MuiTabPanel-root {
  padding-bottom: 20px;
}
// .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
//   background-color: transparent ;
//   box-shadow: none ;
//   overflow-y: hidden ;
// }
#basic-menu .MuiPopover-paper {
  margin-top: 3px;
  margin-left: -14px;
  border: 1px solid #cecece;
  border-radius: 5px;
  width: 250px;
  box-shadow: 0px 0px;
}
#statusPopover .MuiPopover-paper {
  // box-shadow: 0px 0px ;
  border-radius: 0px;
  background-color: transparent;
  margin-left: 99.3px;
}
#sortPopover .MuiPopover-paper {
  background-color: transparent;
  box-shadow: 0px 0px;
  border-radius: 0px;
  padding-left: 10px;
  margin-left: 30px;
  padding-right: 50px;
  background-color: transparent;
}
.MuiPopover-paper {
  box-shadow: none;
  background-color: transparent;
}
.MuiPopover-root {
  box-shadow: none;
  background-color: none;
}
#searchPopover .MuiPopover-paper {
  width: 320px;
  height: 182px;
  border-radius: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}
.MuiDialog-paper {
  border-radius: 18px;
  max-width: 740px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
}

#filterDialog .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(3px);
}
.MuiPaper-root.MuiAccordion-root.Mui-expanded:first-of-type {
  border-radius: 0px;
  // box-shadow: 0px 0px ;
}

.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  min-height: 26px;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 3px 0;
}
.MuiAccordionDetails-root {
  padding-left: 0px;
  // padding-top: 8px ;
}
.css-krifqu-MuiTypography-root {
  font-size: 10px;
  color: #216fb4;
  letter-spacing: -0.08px;
  margin-top: -4px;
}
.MuiFormControlLabel-root {
  padding-left: 12px;
  margin-left: 0px;
  margin-right: 0px;
}
#popoverBorder .MuiPopover-paper {
  border-radius: 0px;
  // box-shadow: 0px 0px ;
  background-color: transparent;
}
.MuiGrid-root.MuiGrid-item.mycontainer {
  margin-bottom: -3px;
  margin-top: -5px;
  margin-left: 0.3px;
}
.MuiDialogContent-root::-webkit-scrollbar {
  display: none;
}
