h1 {
  margin: 0;
  padding-left: 72px;
  line-height: 36px;
  letter-spacing: -0.4px;
  font-family: GothamBlack !important;
  line-height: 36px;
  font-size: 30px !important;
  color: #4a4a4a !important;
  letter-spacing: -0.4px;
}
p.get {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding-left: 72px;
  line-height: 22px;
  letter-spacing: -0.1px;
  font-family: GothamBook !important;
  font-size: 14px !important;
  color: #4a4a4a !important;
}
// p.reqs {
//   margin: 0;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   font-size: 1rem;
//   line-height: 1.5;
//   letter-spacing: 0.00938em;
//   padding-bottom: 60px;
//   font-size: 14px;
//   margin-top: 20px;
//   letter-spacing: 0.1px;
//   line-height: 22px;
//   font-family: GothamBook !important;
//   font-size: 14px !important;
//   color: #4a4a4a !important;
// }
p.para {
  font-size: 18px !important;
  font-family: GothamBold !important;
  color: #262262 !important;
}
