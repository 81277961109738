@import "../../settings/setting.scss";
.b-request-over-view-data {
  &__request-box-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__request-label {
      font-size: $font-size-12;
    font-family: $font-family-GothamBook;
    letter-spacing: -0.4px;
    line-height: 26px;
    color: $primary-color ;
  }
  &__request-label-detail {
    color: $get-started;
    font-family: $font-family-GothamBook;
      font-size: $font-size-12;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    height: 16px;
    letter-spacing: -0.1px;
    line-height: 1.33;
    margin: 10px 28px 10px 0px !important;
    width: 200px;
  }
}
