@font-face {
  font-family: GothamBook;
  src: url(../../assets/fonts/Gotham-Book.otf);
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: GothamLight;
  src: url(../../assets/fonts/Gotham-Light.otf);
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: GothamBookItalic;
  src: url(../../assets/fonts/Gotham-BookItalic.otf);
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: GothamMedium;
  src: url(../../assets/fonts/GothamMedium.otf);
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: GothamBlack;
  src: url(../../assets/fonts/Gotham-Black.otf);
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: GothamBoldItalic;
  src: url(../../assets/fonts/GothamBoldItalic.ttf);
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: GothamBold;
  src: url(../../assets/fonts/Gotham-Bold.otf);
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
