$primary-color: #4a4a4a;
$secondary-color: red;
$orange-color: #f7ab71;
$yellow-color: #ffdc7b;
$tale-green: #c9db85;
$tale-blue: #7ac79b;
$purple: #262262;
$grey: #4a4a4a;
$blue: #1d70be;
$green: #069389;
$light-blue: #1e70b9;
$tale-grey: #a4a4a4;
$tale-white: #cecece;
$footer-color: #216fb4;
$get-started: #262262;
$detail-and-overview-btn-color: #1d70be;
$footer-bar-color: #cecece;
$text-color-footer: #070070;
$spinner-color: white;
$asset-name-color: #216fb4;
$birthday-color: #6cace4;
$font-color: #262262;
$border-color: #a4a4a4;
$dark-grey-color: #4a4a5c;
$body-color: #5eaee0;
$indicator-divider-color: rgba(0, 0, 0, 0.12);
$request-container-color: rgba(0, 0, 0, 0.25);
$white: white;
$scroller-color: #d8d8d8;
$white-1 : #edf7ff;
$off-white: #ededf5;
$asset-new-color: #262163;
$blueish-color: #2170b5;
$grayish-color: #bebebe;
$black : black;
